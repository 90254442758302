import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import * as modalActions from '../../actions/modal'
import * as loginActions from '../../actions/login'
import { renderTermsOfUse } from './render_utils'
import './modal_social_login_component.scss'

class ModalSocialLogin extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    modalActions: PropTypes.object.isRequired,
    onCloseModal: PropTypes.func.isRequired
  }

  componentWillUnmount() {
    this.props.loginActions.clearErrors()
  }

  renderRakutenLogin(returnUrl) {
    const members = this.props.t('sign_in.become_rakuten_member', { returnObjects: true })
    const text = Array.isArray(members) && members.map((member, i) => {
      return (members.length !== 1 && i === 0) ? <em key={i}>{member}</em> : member
    })
    return (
      <div styleName="sns-login__rakuten">
        <a
          href={`/openid/login?return_url=${returnUrl}`}
          styleName="sns-login__btn-rakuten-id-login"
        >
          {this.props.t('sign_in.social.rakuten_id_login_label')}
        </a>
        <a
          href={`/openid/register?return_url=${returnUrl}`}
          styleName="sns-login__link-become-rakuten-member"
        >
          {text}
        </a>
      </div>
    )
  }

  renderOtherSnsLogin(returnUrl) {
    return (
      <div styleName="sns-login__other">
        <form method="POST" name='google' action={`/openid/google/auth?origin=${returnUrl}`}>
          <input type="hidden" name="authenticity_token" value={ document.querySelector('meta[name="csrf-token"]').content } />
        </form>
        <a
          href="javascript:document.forms['google'].submit()"
          styleName="sns-login__google"
        >
          <img
            src="/assets/image/common/icon_google_g.svg"
            alt="login with google"
            styleName="icon"
          />
          {this.props.t('sign_in.social.google_login')}
        </a>
        <form method="POST" name='facebook' action={`/openid/facebook/auth?origin=${returnUrl}`}>
          <input type="hidden" name="authenticity_token" value={ document.querySelector('meta[name="csrf-token"]').content } />
        </form>
        <a
          href="javascript:document.forms['facebook'].submit()"
          styleName="sns-login__facebook"
        >
          <img
            src="/assets/image/common/icon_fb_white.svg"
            alt="login with facebook"
            styleName="icon"
          />
          {this.props.t('sign_in.social.facebook_login')}
        </a>
      </div>
    )
  }

  renderSnsButtons() {
    const { history: { location: { pathname, search } } } = this.props
    const returnUrl = encodeURIComponent(this.props.login.returnUrl || `${pathname}${search}`)
    const isJa = this.props.i18n.language === 'ja'
    const cls = isJa ? 'ja-only' : ''

    return (
      <div styleName={`sns-login ${cls}`}>
        <div styleName="login-form-vs-logo">
          <img
            src="/assets/image/common/logo_oyado_v1.0.svg"
            alt="Oyado"
          />
        </div>
        {isJa
          ? <React.Fragment>
            {this.renderRakutenLogin(returnUrl)}
            {this.renderOtherSnsLogin(returnUrl)}
          </React.Fragment>
          : <React.Fragment>
            {this.renderOtherSnsLogin(returnUrl)}
            {this.renderRakutenLogin(returnUrl)}
          </React.Fragment>
        }
        {renderTermsOfUse(this.props, false)}
      </div>
    )
  }

  render() {
    if (!this.props.modal.isSocialLoginModalShow) return null

    const { t } = this.props

    return (
      <div id="social-login-form" styleName="social-login-form-background">
        <div
          styleName="social-login-form-container"
          onClick={() => {
            this.props.onCloseModal()
          }}
        >
          <div styleName="social-login-form-wrapper">
            <div
              styleName="social-login-form"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div
                styleName="social-login-form__close"
                onClick={() => {
                  this.props.onCloseModal()
                }}
              >
                <img src="/assets/image/common/icon_close.svg" />
              </div>
              {this.renderSnsButtons()}
              <div styleName="login-form-vertical-divider">
                <span styleName="or" className="notranslate">
                  OR
                </span>
              </div>
              <div styleName="social-login-signup">
                <button
                  type="button"
                  styleName="social-login-signup-button"
                  onClick={e => {
                    e.preventDefault()
                    this.props.modalActions.showRegisterModal()
                  }}
                >
                  {t('sign_in.social.signup')}
                </button>
              </div>
              <div styleName="register-form__link-to-login">
                <span>{t('sign_in.register.do_you_have_account')}</span>
                <a
                  onClick={e => {
                    e.preventDefault()
                    this.props.modalActions.showLoginModal()
                  }}
                >
                  {t('sign_in.login')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ login, modal }) => ({
    login,
    modal
  }),
  dispatch => ({
    loginActions: bindActionCreators(loginActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(ModalSocialLogin))
