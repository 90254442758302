import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router'
import { reduxForm, Field, propTypes } from 'redux-form'
import * as loginActions from '../../actions/login'
import * as modalActions from '../../actions/modal'
import { renderTermsOfUse } from './render_utils'
import './modal_login_component.scss'

class ModalLogin extends Component {
  static propTypes = {
    ...propTypes,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired,
    modalActions: PropTypes.object.isRequired,
    onCloseModal: PropTypes.func.isRequired
  }

  componentWillUnmount() {
    this.props.loginActions.clearErrors()
  }

  onSubmit = async formProps => {
    try {
      const { history: { location: { pathname, search } } } = this.props
      const returnUrl = this.props.login.returnUrl || `${pathname}${search}`
      const res = await this.props.loginActions.signin(formProps, returnUrl)
      if (languageHelper.pageLocale != res['locale']) {
        let url = new URL(location.origin + '/' + res['locale'] + returnUrl)
        // handle case: /ja/.../?locale=zh-TW
        if (url.searchParams.has('locale')) {
          url.searchParams.delete('locale')
        }
        location.assign(url.href)
      } else {
        location.assign(returnUrl)
      }
    } catch (e) {
      const { t } = this.props
      if (e.statusCode === 401) {
        this.props.modalActions.showCommonModal({
          title: t('sign_in.errors.resend_title'),
          subTitle: t('sign_in.errors.resend_sub_title'),
          body: t('sign_in.errors.resend_body'),
          button: t('sign_in.errors.resend_button'),
          onClick: async () => {
            try {
              await this.props.loginActions.resendConfirmationEmail(formProps.email)
              this.props.modalActions.showCommonModal({ title: t('sign_in.errors.resend_success') })
            } catch (e) {
              this.props.modalActions.showCommonModal({ title: t('sign_in.errors.resend_failure') })
            }
          }
        })
      }
    }
  }

  renderRakutenLogin(returnUrl) {
    const partialMessages = this.props.t('sign_in.become_rakuten_member', { returnObjects: true })
    const text = Array.isArray(partialMessages) && partialMessages.map((part, i) => {
      return (partialMessages.length !== 1 && i === 0) ? <em key={i}>{part}</em> : part
    })
    return (
      <div styleName="sns-login__rakuten">
        <a
          styleName="sns-login__btn-rakuten-id-login"
          href={`/openid/login?return_url=${returnUrl}`}
        >
          {this.props.t('sign_in.auth.rakuten_id_login_label')}
        </a>
        <a
          styleName="sns-login__link-become-rakuten-member"
          href={`/openid/register?return_url=${returnUrl}`}
        >
          {text}
        </a>
      </div>
    )
  }

  renderOtherSnsLogin(returnUrl) {
    return (
      <div styleName="sns-login__other">
        <form method="POST" name='google' action={`/openid/google/auth?origin=${returnUrl}`}>
          <input type="hidden" name="authenticity_token" value={ document.querySelector('meta[name="csrf-token"]').content } />
        </form>
        <a
          href="javascript:document.forms['google'].submit()"
          styleName="sns-login__google"
        >
          <img
            src="/assets/image/common/icon_google_g.svg"
            styleName="icon"
          />
          {this.props.t('sign_in.auth.google_login')}
        </a>
        <form method="POST" name='facebook' action={`/openid/facebook/auth?origin=${returnUrl}`}>
          <input type="hidden" name="authenticity_token" value={ document.querySelector('meta[name="csrf-token"]').content } />
        </form>
        <a
          href="javascript:document.forms['facebook'].submit()"
          styleName="sns-login__facebook"
        >
          <img
            src="/assets/image/common/icon_fb_white.svg"
            styleName="icon"
          />
          {this.props.t('sign_in.auth.facebook_login')}
        </a>
      </div>
    )
  }

  renderSnsButtons() {
    const { history: { location: { pathname, search } } } = this.props
    const returnUrl = encodeURIComponent(this.props.login.returnUrl || `${pathname}${search}`)
    const isJa = this.props.i18n.language === 'ja'
    const jaOnly = isJa ? 'ja-only' : ''
    return (
      <div styleName={`sns-login ${jaOnly}`}>
        <div
          styleName="login-form__close"
          onClick={() => {
            this.props.onCloseModal()
          }}
        >
          <img src="/assets/image/common/icon_close.svg" />
        </div>
        <div styleName="login-form-vs-logo">
          <img
            src="/assets/image/common/logo_oyado_v1.0.svg"
            alt="Oyado"
          />
        </div>
        {isJa
          ? <Fragment>
            {this.renderRakutenLogin(returnUrl)}
            {this.renderOtherSnsLogin(returnUrl)}
          </Fragment>
          : <Fragment>
            {this.renderOtherSnsLogin(returnUrl)}
            {this.renderRakutenLogin(returnUrl)}
          </Fragment>
        }
      </div>
    )
  }

  renderField = ({ input, label, name, type }) => (
    <div styleName="login-form__input-wrap">
      <label styleName="id-pw-login__input-label">
        <span>{label}</span>
        <input
          {...input}
          styleName="id-pw-login__input"
          id={`login-${input.name}`}
          type={type}
          name={name}
          component="input"
          autoComplete="on"
        />
      </label>
    </div>
  )

  renderErrorMessage() {
    const { signinError } = this.props.login
    if (signinError) {
      return (
        <div styleName="login-form__error-message-placeholder">
          <div styleName="login-form__error-message">
            <span className="vs-icon-exclamation-fill" />
            <span>{signinError}</span>
          </div>
        </div>
      )
    }
  }

  renderForgotYourPassword() {
    const arr = this.props.t('sign_in.auth.forgot_your_password', { returnObjects: true })
    return Array.isArray(arr) && arr.map((str, i) => {
      if (arr.length === 1 || i !== 0) {
        return <a key="forgot_password" href="/users/forgot_password">{str}</a>
      } else {
        return <span key={i}>{str}</span>
      }
    })
  }

  render() {
    if (!this.props.modal.isLoginModalShow) return null
    const { handleSubmit, t } = this.props
    return (
      <div id="login-form" styleName="login-form-background">
        <div
          styleName="login-form-container"
          onClick={() => {
            this.props.onCloseModal()
          }}
        >
          <div styleName="login-form-wrapper">
            {this.renderErrorMessage()}
            <div
              styleName="login-form"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div styleName="contents">
                {this.renderSnsButtons()}
                <div styleName="login-form-vertical-divider">
                  <span styleName="or" className="notranslate">
                    OR
                  </span>
                </div>
                <div styleName="id-pw-login">
                  <h2 styleName="id-pw-login__heading">{t('sign_in.auth.heading')}</h2>
                  <form onSubmit={handleSubmit(this.onSubmit)}>
                    <div styleName="id-pw-login__form-wrapper">
                      <Field
                        name="email"
                        label={t('sign_in.mail_address')}
                        type="email"
                        component={this.renderField}
                      />
                      <Field
                        name="password"
                        label={t('sign_in.password')}
                        type="password"
                        component={this.renderField}
                      />
                      <input value="/" type="hidden" name="return_url" />
                    </div>
                    <input
                      type="submit"
                      name="commit"
                      value={t('sign_in.login')}
                      styleName="id-pw-login__form-submit"
                      data-disable-with="Login"
                    />
                  </form>
                  <p styleName="login-form__forgot-pw">
                    {this.renderForgotYourPassword()}
                  </p>
                  <a
                    styleName="login-form__btn-signup"
                    onClick={e => {
                      e.preventDefault()
                      this.props.modalActions.showSocialLoginModal()
                    }}
                  >
                    {t('sign_in.auth.signup')}
                  </a>
                </div>
                <div styleName="login-form-divider">
                  <span styleName="or" className="notranslate">
                    OR
                  </span>
                </div>
              </div>
              {renderTermsOfUse(this.props, true)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(
    ({ login, modal }) => ({
      login,
      modal
    }),
    dispatch => ({
      loginActions: bindActionCreators(loginActions, dispatch),
      modalActions: bindActionCreators(modalActions, dispatch)
    })
  ),
  reduxForm({ form: 'login' })
)(withRouter(ModalLogin))
